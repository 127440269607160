import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    timeout: Number
  }

  connect() {
    if (this.hasTimeoutValue) {
      if (this.element.tagName == 'TURBO-FRAME') {
        this.inverval = setInterval(() => this.element.reload(), this.timeoutValue);
      } else {
        setTimeout(() => window.location.reload(), this.timeoutValue);
      }
    } else {
      window.location.reload();
    }
  }

  disconnect() {
    if (this.inverval) {
      clearInterval(this.inverval)
    }
  }
}
