import { Controller } from "@hotwired/stimulus";

const paddingElementSelectors = ["body section:first-of-type", "body > .container.grid"]
const topOffsetElementSelectors = [".navigation"]
const transitionTime = '300ms'
const transitionAnimation = 'ease'
const slideInDelayInMs = 500;

export default class extends Controller {
  static targets = ["selector"];

  get mobileView() {
    return window.innerWidth < this.lgBreakpoint
  }

  connect() {
    this.selectorTarget.style.top = `-${this.selectorTarget.offsetHeight}px`;

    window.setTimeout(this.showRegionSelector, slideInDelayInMs);
    let lastWidth = window.innerWidth;
    this.lgBreakpoint = 992;

    window.addEventListener('resize', () => {
      const currentWidth = window.innerWidth;

      if (lastWidth >= this.lgBreakpoint && currentWidth < this.lgBreakpoint) {
        this.showRegionSelector()
      } else if (lastWidth < this.lgBreakpoint && currentWidth >= this.lgBreakpoint) {
        this.showRegionSelector()
      }

      lastWidth = currentWidth;
    });

  }

  showRegionSelector = () => {
    this.selectorTarget.style.transition = `top ${transitionAnimation} ${transitionTime}`;
    this.selectorTarget.style.top = "56px";

    const topOffset = this.selectorTarget.offsetHeight;

    paddingElementSelectors.forEach(selector => {
      const element = document.querySelector(selector);

      if (element && !this.mobileView) {
        element.style.transition = `padding ${transitionAnimation} ${transitionTime}`;
        element.style.paddingTop = `calc(${element.style.paddingTop || '0px'} + ${topOffset}px)`;
      } else if (element && this.mobileView) {
        element.style.transition = `padding ${transitionAnimation} ${transitionTime}`;
        element.style.paddingTop = `0`;
      }
    })

    topOffsetElementSelectors.forEach(selector => {
      const element = document.querySelector(selector);
      if (element && !this.mobileView) {
        element.style.transition = `top ${transitionAnimation} ${transitionTime}`;
        element.style.top = `calc(${element.style.top || '0px'} + ${topOffset}px + 56px)`;
      } else if (element && this.mobileView) {
        element.style.transition = `top ${transitionAnimation} ${transitionTime}`;
        element.style.top = `0`;
      }
    })
  };

  hideRegionSelector = () => {
    const topOffset = this.selectorTarget.offsetHeight;

    this.selectorTarget.style.top = `-${topOffset}px`

    paddingElementSelectors.forEach(selector => {
      const element = document.querySelector(selector);
      if (element) {
        element.style.paddingTop = `calc(${element.style.paddingTop || '0px'} - ${topOffset}px)`;
      }
    })

    topOffsetElementSelectors.forEach(selector => {
      const element = document.querySelector(selector);
      if (element) {
        element.style.top = `calc(${element.style.top || '0px'} - ${topOffset}px)`;
      }
    })

    this.element.addEventListener('transitionend', () => this.element.remove())
  }
}
